<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-flex uk-flex-between uk-margin">
            <div class="uk-flex uk-flex-left">
                <div class="uk-inline">
                    <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                    <input class="uk-input" @keyup.enter="(e) => changeMeta(e, 'name')" type="text" placeholder="Search company leave" />
                </div>
            </div>
            <div v-if="consist(user_cred.roles, ['qwe123', 'superadmin-product','superadmin-tech', 'admin-operation'])" class="uk-flex uk-flex-right">
                <button class="uk-button uk-button-primary uk-border-rounded" type="button" @click="showModal('create-modal', null, false)">Create</button>
            </div>
        </div>
        <div class="uk-card uk-card-default">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium">
                    <thead>
                        <tr class="uk-text-left">
                            <th class="uk-table-expand">Name</th>
                            <th>Category</th>
                            <th>Quota (days)</th>
                            <th class="uk-table-expand">Assigned to</th>
                            <th>Office</th>
                            <th>Job Title</th>
                            <th>Reset Date</th>
                            <th
                                v-if="consist(user_cred.roles, ['qwe123', 'superadmin-product','superadmin-tech', 'admin-operation'])"
                                class="uk-text-center uk-table-shrink"
                            >Actions</th>
                        </tr>
                    </thead>
                    <loading-table v-if="isLoading" :colspan="5"/>
                    <tbody v-else-if="leave_list && leave_list.docs.length > 0">
                        <tr v-for="(leave, i) in leave_list.docs" :key="i" class="uk-text-left">
                            <td>{{ leave.name }}</td>
                            <td>{{ leave.category == 0 ? 'Unpaid' : 'Paid' }}</td>
                            <td>{{ leave.quota }}</td>
                            <td>{{ leave.company_list }}</td>
                            <td>{{ leave.office_list || 'All Offices' }}</td>
                            <td>{{ leave.role_list || 'All Roles' }}</td>
                            <td>{{ leave.reset_date ? datetime.fromISO(leave.reset_date).toFormat('dd/LL') : '-' }}</td>
                            <td v-if="consist(user_cred.roles, ['qwe123', 'superadmin-product','superadmin-tech', 'admin-operation'])" class="uk-align-right">
                                <button class="uk-button uk-button-small uk-button-default" type="button">Actions</button>
                                <div uk-dropdown="mode: click">
                                    <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                        <template class="uk-flex uk-flex-right">
                                            <li><a @click="showModal('create-modal', leave, true)">Edit</a></li>
                                            <li class="uk-nav-divider"></li>
                                            <li v-if="leave.request_leave === 0"><a @click="showModal('delete-modal', leave, false)">Delete</a></li>
                                            <li v-else><a @click="showModal('delete-modal-already-requested', leave, false)">Delete</a></li>
                                        </template>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="7"/>
                </table>
            </div>
            <pagination
                v-if="leave_list"
                :total-data="leave_list.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>

        <!-- Modal Create Leave -->
        <div id="create-modal" class="create-modal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('create-modal')"></button>
                <div class="uk-modal-header">
                    <h2 v-if="isEdit" class="uk-modal-title">Edit Selected Leave</h2>
                    <h2 v-else class="uk-modal-title">Create New Leave</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label">Leave Name <b class="uk-text-danger">*</b></label>
                        <input
                            v-model="form.name"
                            name="Leave Name"
                            type="text"
                            class="uk-input"
                            :class="{'uk-form-danger': errors.has('Leave Name')}"
                            placeholder="Type leave name"
                            v-validate="'required'"
                        />
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('Leave Name')">{{ errors.first('Leave Name') }}</span>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Leave Category <b class="uk-text-danger">*</b></label>
                        <select class="uk-select" id="form-horizontal-select" v-model="form.category" :disabled="request_leave > 0">
                            <option disabled>Select category</option>
                            <option :value=0>Unpaid</option>
                            <option :value=1>Paid</option>
                        </select>
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('Category')">{{ errors.first('Category') }}</span>
                    </div>
                    <div uk-grid>
                        <div class="uk-width-1-2">
                            <label class="uk-form-label">Quota (days) <b class="uk-text-danger">*</b></label>
                            <div>
                                <input
                                    v-model="form.quota"
                                    name="Leave Quota"
                                    type="number"
                                    class="uk-input"
                                    :class="{'uk-form-danger': errors.has('Quota')}"
                                    placeholder="Type quota amount"
                                    v-validate="'required'"
                                />
                                <span class="uk-text-small uk-text-danger" v-show="errors.has('Quota')">{{ errors.first('Quota') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Minimum Work Duration <b class="uk-text-danger">*</b></label>
                        <div class="uk-grid-small uk-child-width-expand@s" uk-grid>
                            <div>
                                <input
                                    v-model="form.minimum_work_duration"
                                    name="Minimum Work Duration"
                                    type="text"
                                    class="uk-input"
                                    :class="{'uk-form-danger': errors.has('Minimum Work Duration')}"
                                    placeholder="0-12 (month)"
                                    v-validate="'required'"
                                />
                                <span class="uk-text-small uk-text-danger" v-show="errors.has('Minimum Work Duration')">{{ errors.first('Minimum Work Duration') }}</span>
                            </div>
                            <div class="uk-margin-small-top">Month(s)</div>
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Reset Date</label>
                        <div class="uk-grid-small uk-child-width-expand@s" uk-grid>
                            <div class="uk-position-relative">
                                <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                                <datetime
                                    v-model="form.reset_date"
                                    id="reset_date"
                                    name="Reset Date"
                                    input-class="uk-input"
                                    type="date"
                                    :min-datetime="new Date().toISOString()"
                                    format="dd/MM"
                                    placeholder="DD/MM"
                                ></datetime>
                            </div>
                            <div class="uk-margin-small-top">
                                <label v-if="form.reset_date" class="uk-form-label">
                                    <input
                                        v-model="form.is_monthly_recurring"
                                        class="uk-checkbox"
                                        name="Monthly Recurring Leave Quota"
                                        type="checkbox"
                                    /> Monthly Recurring Leave Quota
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Assign to... <b class="uk-text-danger">*</b></label>
                        <multiselect
                            :disabled="request_leave > 0"
                            v-model="formSelection.company_ids"
                            name="Assigned Company"
                            label="name"
                            track-by="name"
                            placeholder="Type to search company"
                            v-validate="'required'"
                            :options="company_options"
                            :searchable="true"
                            :loading="isFetching"
                            :multiple="false"
                            :internal-search="false"
                            :options-limit="50"
                            @search-change="setCompanyValues"
                        ></multiselect>
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('Assigned Company')">{{ errors.first('Assigned Company') }}</span>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Office</label>
                        <multiselect
                            :disabled="formSelection.company_ids.length != 1"
                            v-model="formSelection.company_office_ids"
                            name="Company Offices"
                            label="name"
                            track-by="name"
                            placeholder="Type to search office"
                            :options="company_offices"
                            :searchable="true"
                            :loading="isFetchingOffices"
                            :multiple="true"
                            :internal-search="true"
                        ></multiselect>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Job Title</label>
                        <multiselect
                            :disabled="formSelection.company_ids.length != 1"
                            v-model="formSelection.role_ids"
                            name="Company Roles"
                            label="name"
                            track-by="name"
                            placeholder="Type to search role"
                            :options="role_options"
                            :searchable="true"
                            :loading="isFetchingRoles"
                            :multiple="true"
                            :internal-search="true"
                        ></multiselect>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button v-if="isSubmit" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
                    <button v-else class="uk-button uk-button-primary uk-border-rounded" type="button" :disabled="isBtnEdit" @click="sendForm">Save</button>
                </div>
            </div>
        </div>

        <!-- Modal Delete Leave -->
        <div id="delete-modal" class="uk-flex-top delete-modal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('delete-modal')"></button>
                <div class="uk-modal-header uk-text-large"><b>Are you sure to delete this leave?</b></div>
                <div class="uk-modal-body">
                    <span>Name: <span class="uk-text-bolder">{{ form.name }}</span></span><br>
                    <span>Category: <span class="uk-text-bolder">{{ form.category == 0 ? 'Unpaid' : 'Paid' }}</span></span><br>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-danger uk-border-rounded" type="button" @click="deleteData">Delete</button>
                </div>
            </div>
        </div>

        <!-- Modal Delete Leave If user already request -->
        <div id="delete-modal-already-requested" class="uk-flex-top delete-modal-already-requested" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('delete-modal-already-requested')"></button>
                <div class="uk-modal-header uk-text-large"><b>This user cannot be deleted</b></div>
                <div class="uk-modal-body">
                    <span>Please complete the request below before proceeding to delete this leave.</span><br>
                    <span>User Requested: <span class="uk-text-bolder">{{ request_leave }} left </span></span><br>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-danger uk-margin-small-left" type="button" @click="cancelDeletionUserRequest">Back</button>
                </div>
            </div>
        </div>

        <div id="delete-confirmation" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('delete-confirmation')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-left">Are you sure?</h2>
                </div>
                <div class="uk-modal-body">
                    <p>Company Leave "{{ form.name }}" will be deleted.</p>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-danger uk-margin-small-left" type="button" @click="cancelDeletion">No</button>
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="acceptDeletion">Yes</button>
                </div>
            </div>
        </div>

        <div id="delete-failed" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('delete-failed')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Company Leave "{{ form.name }}" sudah pernah digunakan.</h2>
                </div>
                <div class="uk-modal-body">
                    <p>Anda tidak bisa menghapus leave yang pernah digunakan.<br> Anda hanya bisa mengubah data.</p>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="hideModal('delete-failed')">Kembali</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Datetime } from 'vue-datetime';
import { DateTime } from 'luxon';
import Pagination from '@/components/globals/Pagination';
import LoadingTable from '@/components/globals/tables/Loading';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { getUserLogin } from '@/utils/auth';
// import formatter from '@/utils/formatter';

export default {
    data() {
        return {
            datetime: DateTime,
            isBtnEdit: false,
            request_leave: 0,
            actual_quota: 0,
            isOpen: false,
            user_cred: getUserLogin(),
            meta: {
                limit: 50,
                page: 1,
                name: '',
                category: ''
            },
            leave_list: null,
            company_options: [],
            company_offices: [],
            role_options: [],
            selected_leave_id: null,
            isMoreThanQuota: false,
            higherRequestLeave: 0,
            form: {
                name: '',
                category: '',
                quota: '',
                is_monthly_recurring: false,
                minimum_work_duration: null,
                reset_date: '',
                company_ids: [],
                company_office_ids: [],
                role_ids: [],
            },
            formSelection: {
                company_ids: [],
                company_office_ids: [],
                role_ids: [],
            },
            isLoading: false,
            isFetching: false,
            isFetchingOffices: false,
            isFetchingRoles: false,
            isSubmit: false,
            isEdit: false,
        };
    },
    components: {
        EmptyTable,
        Pagination,
        LoadingTable,
        Datetime
    },
    computed : {
        ...mapGetters({
            companies: 'company/companies',
        })
    },
    watch: {
        meta() {
            this.getCompanyLeaveList();
        },
        'formSelection.company_ids'(val) {
            if (!Array.isArray(val)) {
                this.formSelection.company_ids = [val];
            }

            if (val.length == 1) {
                this.getOfficeList();
            }
        },
        'formSelection.company_office_ids'(newVal, oldVal) {
            if (newVal.length != oldVal.length) {
                if (newVal.length > oldVal.length) {
                    if (this.checkAllOffices(oldVal)) {
                        this.formSelection.company_office_ids = newVal.filter((item) => item.name != 'All Offices');
                    } else if (this.checkAllOffices(newVal)) {
                        this.formSelection.company_office_ids = newVal.filter((item) => item.name == 'All Offices');
                    }
                }
            }

            if ((newVal.length != oldVal.length) && !this.checkAllOffices(newVal)) {
                this.getRoleList();
            }
        },
        'formSelection.role_ids'(newVal, oldVal) {
            if (newVal.length != oldVal.length) {
                if (newVal.length > oldVal.length) {
                    if (this.checkAllRoles(oldVal)) {
                        this.formSelection.role_ids = newVal.filter((item) => item.name != 'All Roles');
                    } else if (this.checkAllRoles(newVal)) {
                        this.formSelection.role_ids = newVal.filter((item) => item.name == 'All Roles');
                    }
                }
            }
        },
    },
    beforeMount() {
        if(window.UIkit.modal('.create-modal')) window.UIkit.modal('.create-modal').$destroy(true);
        if(window.UIkit.modal('.delete-modal')) window.UIkit.modal('.delete-modal').$destroy(true);
        if(window.UIkit.modal('.delete-modal-already-requested')) window.UIkit.modal('.delete-modal-already-requested').$destroy(true);
    },
    async mounted() {
        await this.getCompanyLeaveList();
    },
    methods: {
        ...mapActions({
            getLeaveList: 'leave/getLeaveList',
            addNewLeave: 'leave/addNewLeave',
            updateLeave: 'leave/updateLeave',
            deleteLeave: 'leave/deleteLeave',
            checkUsedLeave: 'leave/checkUsedLeave',
            getCompanies: 'company/getCompanies',
            getOfficeOpt: 'warung/getOfficeOpt',
            getRolesByOffice: 'option_data/getRolesByOffice',
        }),
        checkQuota(val) {
            if (val < this.higherRequestLeave) {
                notificationDanger('Quota cannot lower than existing approved request.');
                return false;
                // this.form.quota = this.actual_quota;
            }
            return true;
        },
        checkUserApproved(data) {
            if (data === null ) return false;
            // SET REQUEST LEAVE TERBANYAK DI ANTARA USER LAIN
            return data.user.sort((a,b) => b.total_approved_leave - a.total_approved_leave)[0].total_approved_leave; // b - a for reverse sort
        },
        async getCompanyLeaveList(){
            this.isLoading = true;
            this.leave_list = await this.getLeaveList(this.meta);
            this.isLoading = false;
        },
        async setCompanyValues(query) {
            this.isFetching = true;
            await this.getCompanies({ name: query });
            this.company_options = this.companies.docs.map(
                obj => ({name: obj.name, value: obj._id})
            );
            this.isFetching = false;
        },
        async showModal(modal, data=null, edit=false){
            const closeDropdown = window.UIkit.dropdown('.uk-dropdown', {
                delayHide: 0
            });
            closeDropdown ? closeDropdown.hide() : null;
            this.isOpen = false;
            switch (modal) {
            case 'create-modal':
                this.resetForm();
                if (data) {
                    this.selected_leave_id = data._id;
                }
                if (edit) {
                    this.isEdit = true;

                    this.higherRequestLeave = this.checkUserApproved(data.approved_user);
                    data.company_ids.forEach((el, idx) => {
                        const obj = {
                            name: data.company_list.split(',')[idx],
                            value: el
                        };
                        this.formSelection.company_ids.push(obj);
                    });

                    if (data.company_office_ids.length > 0) {
                        data.company_office_ids.forEach((el, idx) => {
                            const obj = {
                                name: data.office_list.split(',')[idx],
                                value: el
                            };
                            this.formSelection.company_office_ids.push(obj);
                        });
                    } else {
                        this.formSelection.company_office_ids.push({ _id: '', name: 'All Offices'});
                    }

                    if (data.role_ids.length > 0) {
                        data.role_ids.forEach((el, idx) => {
                            const obj = {
                                name: data.role_list.split(',')[idx],
                                value: el
                            };
                            this.formSelection.role_ids.push(obj);
                        });
                    } else {
                        this.formSelection.role_ids.push({ _id: '', name: 'All Roles'});
                    }

                    this.form.id = data._id;
                    this.form.name = data.name;
                    this.form.category = data.category;
                    this.form.quota = data.quota;
                    this.form.is_monthly_recurring = data.is_monthly_recurring;
                    this.form.minimum_work_duration = data.minimum_work_duration;
                    this.form.reset_date = data.reset_date;

                    this.request_leave = data.request_leave;
                    this.actual_quota = data.quota;
                }
                await window.UIkit.modal('#create-modal').show();
                break;
            case 'delete-modal':
                if (data) {
                    this.selected_leave_id = data._id;
                    this.form.name = data.name;
                    this.form.category = data.category;
                }
                await window.UIkit.modal('#delete-modal').show();
                break;
            case 'delete-modal-already-requested':
                if (data) {
                    this.selected_leave_id = data._id;
                    this.request_leave = data.request_leave;
                }
                await window.UIkit.modal('#delete-modal-already-requested').show();
                break;
            case 'delete-confirmation':
                await window.UIkit.modal('#delete-confirmation').show();
                break;
            case 'delete-failed':
                await window.UIkit.modal('#delete-failed').show();
                break;
            }
        },
        async hideModal(modal){
            switch (modal) {
            case 'create-modal':
                await window.UIkit.modal('#create-modal').hide();
                break;
            case 'delete-modal':
                await window.UIkit.modal('#delete-modal').hide();
                break;
            case 'delete-modal-already-requested':
                await window.UIkit.modal('#delete-modal-already-requested').hide();
                break;
            case 'delete-confirmation':
                await window.UIkit.modal('#delete-confirmation').hide();
                break;
            case 'delete-failed':
                await window.UIkit.modal('#delete-failed').hide();
                break;
            }
        },
        async getOfficeList(){
            try {
                this.isFetchingOffices = true;

                this.company_offices = await this.getOfficeOpt(this.formSelection.company_ids[0].value);

                if (this.company_offices.length > 0) {
                    this.company_offices.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
                    await this.getRoleList();
                }
                this.company_offices.unshift({ _id: '', name: 'All Offices'});
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.isFetchingOffices = false;
            }
        },
        async getRoleList(){
            try {
                this.isFetchingRoles = true;

                let company_office_ids;
                const officeIds = this.formSelection.company_office_ids.filter((item) => item.name != 'All Offices');
                if (officeIds.length > 0) {
                    company_office_ids = this.formSelection.company_office_ids.map(office => office._id);
                } else {
                    company_office_ids = this.company_offices.filter((item) => item.name != 'All Offices').map(office => office._id);
                }
                this.role_options = await this.getRolesByOffice({ company_office_ids });

                if (this.role_options.length > 0) {
                    this.role_options.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
                }
                this.role_options.unshift({ _id: '', name: 'All Roles'});
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.isFetchingRoles = false;
            }
        },
        checkAllOffices(array) {
            const isAllOfficeSelected = array.find((item) => item.name == 'All Offices');
            return isAllOfficeSelected;
        },
        checkAllRoles(array) {
            const isAllRoleSelected = array.find((item) => item.name == 'All Roles');
            return isAllRoleSelected;
        },
        resetForm() {
            this.selected_leave_id = '';
            this.form = {
                name: '',
                category: '',
                quota: '',
                is_monthly_recurring: false,
                minimum_work_duration: null,
                reset_date: '',
                company_ids: [],
                company_office_ids: [],
                role_ids: [],
            };
            this.formSelection = {
                company_ids: [],
                company_office_ids: [],
                role_ids: [],
            };
            this.request_leave = 0;
            this.isEdit = false;
            this.$validator.reset();
        },
        async sendForm() {
            try {
                if (!this.checkQuota(this.form.quota)) { this.form.quota = this.actual_quota; return; }
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.isSubmit = true;
                this.form.company_ids = this.formSelection.company_ids.map(
                    company => company.value ? company.value : company._id
                );
                if (this.formSelection.company_office_ids.length > 0) {
                    this.form.company_office_ids = this.formSelection.company_office_ids
                        .filter((item) => item.name != 'All Offices')
                        .map(office => office.value ? office.value : office._id);
                }
                if (this.formSelection.role_ids.length > 0) {
                    this.form.role_ids = this.formSelection.role_ids
                        .filter((item) => item.name != 'All Roles')
                        .map(role => role.value ? role.value : role._id);
                }

                // const year = formatter.getYearOnly();
                // this.form = {
                //     ...this.form,
                //     year_active: year
                // };

                const response = this.isEdit
                    ? await this.updateLeave(this.form)
                    : await this.addNewLeave(this.form);
                this.isSubmit = false;

                if (response) {
                    if(this.isEdit) {
                        notificationSuccess('Company Leave updated');
                    } else {
                        notificationSuccess('New Company Leave added');
                    }
                    await this.hideModal('create-modal');
                    await this.getCompanyLeaveList();
                    this.resetForm();
                } else {
                    await this.hideModal('create-modal');
                    notificationDanger('Failed to add!');
                }
            } catch (err) {
                notificationDanger(err);
            }
            await this.hideModal('create-modal');
        },
        async deleteData() {
            try {
                this.isSubmit = true;

                // const checkLeave = await this.checkUsedLeave(this.selected_leave_id);
                await this.hideModal('delete-modal');

                // if(checkLeave) {
                //     await this.showModal('delete-failed', this.form, false);
                // } else {
                await this.showModal('delete-confirmation', this.form, false);
                // }

                this.isSubmit = false;
            } catch (err) {
                notificationDanger(err);
            }
        },
        async acceptDeletion() {
            try {
                this.isSubmit = true;
                const response = await this.deleteLeave(this.selected_leave_id);
                this.isSubmit = false;
                
                if (response && response.status === 'OK') {
                    notificationSuccess('Deleted!');
                    this.hideModal('delete-confirmation');
                    await this.getCompanyLeaveList();
                    this.resetForm();
                } else {
                    notificationDanger('Failed to delete!');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        async cancelDeletion() {
            this.resetForm();
            await this.hideModal('delete-confirmation');
        },
        async cancelDeletionUserRequest() {
            await this.hideModal('delete-modal-already-requested');
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
    },
};
</script>

<style scoped></style>
